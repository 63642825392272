/**
***************************************************************************
* NAME 			: DataSnapSummary
* PROPS	 		: -- (array)
* RETURNS 		: Container for the home card populated with data for
*				: the specific dashboard compoonent
* LAST MODIFIED 	: March 1, 2024
***************************************************************************
*/

import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { getAnalytics } from '../../../api/datasnap';

// Lazy load possible components
const Analytics1 = React.lazy(() => import('./Analytics1'));

const DataSnapSummary = () => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [component, setComponent] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const { sendRequest, status, data, error } = useHttp(getAnalytics);

	const components = {
		Analytics1
//		Analytics2,
//		Analytics3,
	};
	const DynamicComponent = ({ componentName, ...props }) => {
		const ComponentToRender = components[componentName];
		return ComponentToRender ? (
			<ComponentToRender {...props} />
		) : (
			// notifyCtx.displayNotification(
			// 	"ERROR",
			// 	"Analysis by County not found"
			// )
			<p>Analyics not found</p>
		);
	};

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	error
				// )
				setErrorMessage(error);
			} else if (!data['success']) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	data['status_code'] + ': ' + data['status']
				// )
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else {
				// console.log('DATASNAP DATA');
				// console.log(data['data']);

				setComponent(data['data']);
			}
			setIsLoading(false);

			// Reset timeout
			// authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		setIsLoading(true);

		const requestData = {
			token: authCtx.token,
			dashboard: authCtx.dashboard
		};

		sendRequest(requestData);
	}, [sendRequest, authCtx.dashboard]);

	return (
		<>
			{ !isLoading && errorMessage &&
				<p>{errorMessage}</p>
			}
			{ !isLoading && !errorMessage &&
				<DynamicComponent componentName={component.componentName} />
			}
		</>
  	)
}

export default DataSnapSummary;